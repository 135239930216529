<template>
  <main>
    <div class="banner">
      可用积分
      <div class="integral" style="font-size: 15px" v-if="intergralData">  {{ intergralData.jiFen }}</div>
      <ul class="menu-nav">
        <li><a href="javascript:;" rel="nofollow">积分规则</a></li>
      </ul>
    </div>
    <div class="rule-content">
      <strong class="rule-title"> 积分规则： </strong>
      <p>
        订购产品、注册、签到、发帖、发表文章、论文、课程等，均可获得积分，可用于兑换礼品。
      </p>
      <div class="rule-table-title">1.获取积分：</div>
      <table border="1" cellspacing="0" cellpadding="10px">
        <thead>
          <tr>
            <th class="name" style="background: rgb(233, 241, 245)">任务</th>
            <th>获得积分</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="name">注册</td>
            <td>300</td>
          </tr>
          <tr>
            <td class="name">关联账号</td>
            <td>300</td>
          </tr>
          <tr>
            <td class="name">
              <router-link :to="{ name:'UserInfo' }">完善资料</router-link>
            </td>
            <td>300</td>
          </tr>
          <!--<tr>
            <td class="name">  <router-link :to="{ name:'Address' }">完善地址</router-link></td>
            <td>300</td>
          </tr>-->
          <tr>
            <td class="name"> <router-link :to="{ name:'signIn' }">每日签到</router-link></td>
            <td>每日签到获得30积分，连续签到5天奖励100积分</td>
          </tr>
          <tr>
            <td class="name">
              <router-link :to="{ name:'PostTopic' }">发布帖子</router-link>
            </td>
            <td>100</td>
          </tr>
          <tr>
            <td class="name">下单</td>
            <td>订单金额1元=1积分</td>
          </tr>
        </tbody>
      </table>
      <div class="rule-table-title">2. 积分兑换礼品</div>
      <p>
        会员账户内的积分可用于兑换相应礼品，进入
        <router-link :to="{ name:'Intergral' }">【积分商城】</router-link>
        即可直接兑换。
      </p>
      <div class="rule-table-title">3. 积分使用规则</div>
      <p>（1）用户获得但未使用的积分，将在每年12月31号统一清零。</p>
      <p>（2）积分使用过程中不找零、不兑现、不开发票，不可转移至其他账户。</p>
      <p>
        （3）使用积分进行兑换，兑换申请一经提交,
        一律不能取消(除礼品缺货等特殊情况)。
      </p>
      <p>（4）线上订单退货后会扣除相对应的积分。</p>
      <p>（5）兑换实物礼品涉及运费由伟业计量承担。</p>
      伟业计量对积分规则拥有最终解释权。
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent , ref } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
export default defineComponent({
  name: "IntergralRule",
  setup(){
       const intergralData = ref(null);

     axios
      .get("/M/User/ExchangeProduct")
      .then((res) => {
        if (res.data.success) {
          intergralData.value = res.data.obj;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log('err')
      });
      return {
        intergralData
      }
  }
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 110px;
}

.banner {
  width: 100%;
  height: auto;
  position: relative;
  background-image: linear-gradient(-90deg, #d64b4b 0%, #e86464 100%),
    linear-gradient(#eeeeee, #eeeeee);
  background-blend-mode: normal, normal;
  padding-top: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  font-size: 15px;
}

.rule {
  position: absolute;
  right: 0;
  top: 20px;
  padding-right: 9px;
  color: #ffffff;
  font-size: 15px;
}

.rule img {
  width: 13.5px;
  height: 12.5px;
  margin-right: 2.5px;
}

.integral {
  color: #ffffff;
  font-size: 20px;
  margin-top: 7.5px;
}

/**

    菜单导航
  */
.menu-nav {
  overflow: hidden;
  height: 45px;
  line-height: 45px;
  margin-top: 20px;
}

.menu-nav li {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}
.menu-nav li a {
  display: block;
  text-align: center;
  line-height: 45px;
  width: 100%;
  font-size: 15px;
  color: #fff;
}
.menu-nav .active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7.5px solid #fff;
  margin-left: -6px;
}

table {
  width: 355px;
  border: none;
  margin: 0 auto;
  margin-top: 20px;
  border-collapse: collapse;
  border-color: #333;
  background-color: #f3f3f3;
  text-align: center;
}
thead {
  width: 355px;
  background: rgb(233, 241, 245);
  text-align: center;
}
th {
  color: #333;
  font-weight: bold;
  text-align: center;
  height: 30px;
  box-sizing: border-box;
  padding: 0 9px;
  line-height: 30px;
  font-size: 16px;
}
td {
  font-size: 16px;
  color: #333;
  padding: 0 10px;
}

.name {
  width: 117px;
  box-sizing: border-box;
  border-right-color: #333;
  background-color: #f3f3f3;
  line-height: 25px;
}

.rule-title {
  color: rgb(51, 51, 51);
  letter-spacing: 0px;
  font-size: 18px;

  font-family: 微软雅黑;
}

.rule-content {
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 10px;
  color: rgb(51, 51, 51);
  letter-spacing: 0px;
  font-size: 16px;
  background: rgb(255, 255, 255);
  font-family: 微软雅黑;
}

.rule-content p {
  margin: 12.5px 0;
  text-indent: 1.5em;
}

.rule-content a {
  color: #3d3dec;
  text-decoration: underline;
}

.rule-table-title {
  color: rgb(51, 51, 51);
  letter-spacing: 0px;
  font-size: 16px;
  background: rgb(255, 255, 255);
  font-weight: bold;
  font-family: 微软雅黑;
}
</style>
